import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Gift } from '../components/gift'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import getData from '../params/getdata'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#B99225'
let black = 'rgb(38,38,38)'

let id = 'reza-refie'
let inisial_co = 'Refie'
let inisial_ce = 'Reza'

let lengkap_co = (<>Muhammad Refie Abdurrakhman S.T</>)
let lengkap_ce = (<>Reza Rizki Amalia S.E</>)

let bapak_co = 'Bpk H. Agung Swasono'
let ibu_co = 'Ibu Hj. Ninetine Agustine'
let bapak_ce = "Bpk Peltu (Purn) Aminsyah"
let ibu_ce = "Ibu Laesa Nurhizanah"

let ig_co = "mrefie"
let ig_ce = "rezarizkiamalia"

let foto_ce = pw("reza-rafie", "nama.jpg")
let foto_co = "https://i.ibb.co/wRYZWc4/Individu-1.jpg"
let waktunikah = "12/26/2020"

let modal = pw("reza-rafie", "Modal.jpg")
let openlogo = pw("reza-rafie", "Logo.png")

let gmaps = "https://goo.gl/maps/fsXGAo5RYXP5P1pw8"
let gcalendar = "https://calendar.google.com/event?action=TEMPLATE&tmeid=N2M4dmQ1bDVjcDVocDV2M3FiYmZnZmxxbDEgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com"
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1014771.5421041007!2d106.50938377472944!3d-6.527830713941141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69c5f6957a3c2b%3A0x77770b15b0732ca0!2sVilla%20Hidroponik!5e0!3m2!1sid!2sid!4v1607265659895!5m2!1sid!2sid"
let slide = [pw("reza-rafie", "Slide 1.jpg"),
pw("reza-rafie", "Slide 2.jpg"),
pw("reza-rafie", "Slide 3.jpg"),
pw("reza-rafie", "Slide 4.jpg"),
pw("reza-rafie", "Slide 5.jpg"),
pw("reza-rafie", "Slide 6.jpg"),
pw("reza-rafie", "Slide 7.jpg"),
pw("reza-rafie", "Slide 8.jpg"),
pw("reza-rafie", "Slide 9.jpg"),
pw("reza-rafie", "Slide 10.jpg"),
]



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            comment: [],
            showgift:false
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment=await getData('kepada:"reza-refie"')
        
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw("reza-rafie", "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "reza-refie", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, hide, submitted, err, sesi, comment ,showgift} = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''


        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' className="position-relative" style={{backgroundImage:`url('https://i.pinimg.com/originals/c6/ce/59/c6ce59ea7374a53ae7a3766c087b21ac.jpg')`,
                backgroundRepeat:'no-repeat',
                backgroundAttachment:'fixed',
                backgroundSize:'100vw 100vh',
                backgroundColor: 'black'
}}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={`position-relative ${hide ? 'd-none' : 'd-block'}`} >
                            <div id="top" style={{backgroundImage:`url('https://i.pinimg.com/originals/c6/ce/59/c6ce59ea7374a53ae7a3766c087b21ac.jpg')`,
                backgroundRepeat:'no-repeat',
                backgroundAttachment:'fixed',
                backgroundSize:'100vw 100vh',
                backgroundColor: 'black'
}}

                            >
                                <Container className="dinny px-3 pt-5 " style={{ backgroundColor: 'transparent', zIndex: 999999 }}>
                                    <Item>

                                        <p className="fs16 text-center px-3" style={{ color: 'white' }}>
                                            And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.<br /><br />(Ar- Rum 21)

                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container fluid id='sectiongold55' className="py-5 dinny"
                                >
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <Row className="h-100">
                                                <div className="my-auto mx-auto">
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                            {inisial_ce}
                                                        </h1>
                                                    </Item>

                                                    <Item >
                                                        <h1 className="py-3 w-100 text-center"
                                                            style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                            {lengkap_ce}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center'
                                                            style={{ fontSize: '18px', color: 'white' }}>
                                                            <b>Putri dari :</b><br />
                                                            {bapak_ce}  <br />
                                                        &<br />
                                                            {ibu_ce}
                                                        </p>
                                                    </Item>
                                                    <Item>

                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />
                                                        <p style={{ fontSize: '16px', color: 'white' }} className="w-100 text-center">
                                                            @{ig_ce}
                                                        </p>
                                                    </Item>
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={4} className="px-md-3 reza">
                                            <img src={foto_ce} className="w-100 img-fluid" />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Row className="h-100">
                                                <div className="my-auto mx-auto">
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                            {inisial_co}
                                                        </h1>
                                                    </Item>

                                                    <Item>
                                                        <h1 className="py-3 w-100 text-center"
                                                            style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                            {lengkap_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center' style={{
                                                            fontSize: '20px',
                                                            color: 'white'
                                                        }}>
                                                            <b>Putra dari :</b><br />
                                                            {bapak_co}  <br />
                                                        &<br />
                                                            {ibu_co}
                                                        </p>
                                                    </Item>
                                                    <Item>
                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                                    </Item>
                                                    <p style={{ fontSize: '16px', color: 'white' }} className="w-100 text-center">
                                                        @{ig_co}
                                                    </p>
                                                </div>
                                            </Row>

                                        </Col>
                                    </Row>
                                </Container>
                                <Container fluid className="text-center px-4 py-3 dinny" style={{ color: 'white' }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaallah  akan dilaksanakan pada:
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">26</span> DES 2020
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: cmain }}>
                                            <b>Akad Nikah </b><br />
                                            <span className=" fs16" style={{ color: 'white' }}>
                                                <br />
                                                08.00 - 10.00 WIB
                      </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>
                                        <p className="px-3 d-none d-md-block" style={{ color: cmain, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                                            <b>Resepsi</b><br />

                                            <span className=" fs16" style={{ color: 'white' }}>
                                                <br />
                                               Sesi 1: 11.00 - 12.30 WIB<br />
                                               Sesi 2: 12.30 - 14.00 WIB

                                               </span>


                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3">
                                            <b>Villa Hidroponik
                                            <br />
                                            Jln. Raya Cifor No.41, Bogor
                                            </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>

                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset", "covid-gold.png")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>

                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            Another day has gone, Another night just passed. Now my life is complete with you.


                                                        </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                <Container className="py-3" >
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Send Gift</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                               {showgift?(
                                    <Gift
                                    reza
                                    content={
                                        [
                                            {
                                                bank: 'BCA',
                                                norek: '0954072475',
                                                nama: 'Reza Rizki Amalia'
                                            },
                                            {
                                                bank: 'Mandiri',
                                                norek: '1330012524088 ',
                                                nama: 'Muhammad Refie Abdurrakhman'
                                            }
                                        ]
                                    }
                                    caption='For those who want to give gifts to our wedding, kindly transfer to the following accounts :'
                                    ccaption="white"
                                    color={'white'}
                                    bg={cmain}
                                    langsung
                                    alamat="Perumahan Bukit Asri Ciomas Blok A2 no.1, Kel. Pagelaran, Kec. Ciomas, Kab. Bogor, 16610"
                                />
                               ):false}
                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container className="py-5 px-md-5 col-md-8 px-3"
                                    style={{ color: 'white', fontFamily: 'Patrick Hand, cursive' }}
                                >

                                    <Row className="position-relative px-5 py-5" style={{ backgroundColor: 'rgba(255,255,255,0.1)' }}>
                                        <h1 className="peri w-100 text-center">
                                            Ucapan dan doa
                                        </h1>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
                                        </Col>
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                            {comment.map((v, i) => {
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                                                " {v.pesan} " <br /> - {v.dari} -
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            })
                                            }
                                        </Row>

                                    </Row>

                                </Container>
                                


                                <Foot ig={logoig} />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

